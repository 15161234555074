import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import Dialog from '@/components/bootstrap-vue-dialog'
// Global Components
import './global-components'
import '@/libs/acl'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import VueApollo from 'vue-apollo'
import underscore from 'vue-underscore'
import { datter } from '@/libs/dateTool'
import {axiosIns} from '@/libs/axios.js';

import {
  ApolloClient, HttpLink, InMemoryCache, split,
} from '@apollo/client/core'
/*
const rouletteClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'http://cashio.test/graphql'
  // uri: 'https://dev-apis-cashio.bingolar.tv/graphql'
  uri: config.VUE_CONFIG_ROULETTE_API,
  cache: new InMemoryCache(),

}); */

import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import App from './App.vue'
import store from './store'
import router from './router'
// import VueAxios from 'vue-axios'

Vue.prototype.$datter = datter

Vue.use(underscore)

Vue.use(VueApollo)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Dialog)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
const walletClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'http://wallet.test/graphql'
  // uri: 'https://dev-apis-wallet.bingolar.tv/graphql'
  uri: config.VUE_CONFIG_WALLET_API,
  cache: new InMemoryCache(),
  headers: {
    'x-token-api': '64069316-3f27-4185-8f9b-d6bbd3812a9f' // Substitua 'SeuTokenAqui' pelo seu token real
  }

})
const identityClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'http://identity.test/graphql',
  // uri: 'https://dev-apis-identity.bingolar.tv/graphql'
  uri: config.VUE_CONFIG_IDENTITY_API,
  cache: new InMemoryCache(),
  headers: {
    'x-token-api': '64069316-3f27-4185-8f9b-d6bbd3812a9f' // Substitua 'SeuTokenAqui' pelo seu token real
  }
})

const cashioClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'http://cashio.test/graphql'
  // uri: 'https://dev-apis-cashio.bingolar.tv/graphql'
  uri: config.VUE_CONFIG_CASHIO_API,
  cache: new InMemoryCache(),
  headers: {
    'x-token-api': '64069316-3f27-4185-8f9b-d6bbd3812a9f' // Substitua 'SeuTokenAqui' pelo seu token real
  }
})

const loginClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'http://cashio.test/graphql'
  // uri: 'https://dev-apis-cashio.bingolar.tv/graphql'
  uri: config.VUE_CONFIG_IDENTITY_API,
  cache: new InMemoryCache(),
})
// Create the subscription websocket link
// const wsLink = new WebSocketLink({
//   uri: config.VUE_CONFIG_ROULETTE_API.replace('http', 'ws'),
//   options: {
//     reconnect: true,
//   },
// })

// const gamesClient = new ApolloClient({
//   // You should use an absolute URL here
//   // uri: 'http://cashio.test/graphql'
//   // uri: 'https://dev-apis-cashio.bingolar.tv/graphql'
//   uri: config.VUE_CONFIG_GAMES_API,
//   cache: new InMemoryCache(),
// })

// const httpLink = new HttpLink({
//   // You should use an absolute URL here
//   uri: config.VUE_CONFIG_ROULETTE_API,
// })

// // Create the subscription websocket link
// const wsLink = new WebSocketLink({
//   uri: config.VUE_CONFIG_ROULETTE_API.replace('http', 'ws'),
//   options: {
//     reconnect: true,
//   },
// })

const gameClient = new ApolloClient({
  // You should use an absolute URL here
  uri: config.VUE_CONFIG_GAME_API,
  cache: new InMemoryCache(),
  headers: {
    'x-token-api': '64069316-3f27-4185-8f9b-d6bbd3812a9f' // Substitua 'SeuTokenAqui' pelo seu token real
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return definition.kind === 'OperationDefinition'
//       && definition.operation === 'subscription'
//   },
//   // wsLink,
//   // httpLink,
// )

// Create the apollo client
// const chatClient = new ApolloClient({
//   link,
//   cache: new InMemoryCache(),
//   connectToDevTools: true,
// })

const apolloProvider = new VueApollo({
  clients: {
    walletClient,
    identityClient,
    cashioClient,
    // rouletteClient: chatClient,
    // chatClient,
    gameClient,
    loginClient
  },
  defaultClient: cashioClient,
})

Vue.filter('brl', value => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))

Vue.filter('userDocument', (value, document) => (value.documents.filter(d => d.type == document).length > 0 ? value.documents.filter(d => d.type == document)[0].value : 'Sem Info'))

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
